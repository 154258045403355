@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.checkbox {
	display: flex;
	justify-content: center;
	align-items: center;

	position: relative;

	cursor: pointer;

	width: 1em;
	height: 1em;

	border-radius: 0.125em;
	box-shadow: #a6a6a6 0 0 0 0.0625em inset;

	&::before {
		content: '';
		position: absolute;
		width: 1em;
		height: 1em;
		border-radius: 0.125em;
		background-color: var(--checkbox-color);

		mask-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 64 64"><path d="M0,0v64h64V0H0ZM23.55,50.8l-15.92-15.92,4.78-4.78,11.13,11.13,28.04-28.04,4.78,4.78L23.55,50.8Z"/></svg>');

		mask-size: calc(1em) calc(1em);
		mask-repeat: no-repeat;
		mask-position: center;

		opacity: 0;
		transition: all 0.1s ease-out;
	}

	// contain: paint;

	&:focus {
		outline: none;
		box-shadow: var(--checkbox-color) 0 0 0 0.125em inset;
	}

	&[data-checked='true'] {
		box-shadow: var(--checkbox-color) 0 0 0 0.0625em inset;

		&:focus {
			box-shadow: var(--checkbox-color) 0 0 0 0.125em inset, var(--checkbox-color) 0 0 5px 2px;
		}

		&::before {
			opacity: 1;
			transition: all 0.1s ease-out;
		}
	}

	&[data-variant='circle'] {
		border-radius: 50%;

		&::before {
			border-radius: 50%;
		}
	}

	transition: all 0.1s ease-out;
}

.focus_target {
	width: 1em;
	height: 1em;
	border-radius: 2px;
	box-shadow: var(--checkbox-color) 0 0 0 1px inset, var(--checkbox-color) 0 0 0.25em 0.125em;

	opacity: 0;
	transition: opacity 0.1s ease-out;
}

.checkbox input:checked:focus + .focus_target {
	opacity: 0.4;
}
