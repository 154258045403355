@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

$max-height: min(95svh, 58rem);

.container {
	background-color: #121212;
	color: $white;

	max-height: $max-height;

	display: grid;
	grid-template-columns: 1fr 1fr;

	align-items: center;
	justify-content: center;

	@include sm-down {
		grid-template-columns: 1fr;
		grid-template-rows: 21rem 1fr;
		padding: 1.25rem;

		min-height: unset;
		max-height: unset;
		height: auto;
	}
}

.image {
	height: 100%;

	img {
		height: 100%;
		max-height: $max-height;
		object-position: top center;

		@include sm-down {
			max-height: unset;
		}
	}
}

.container header {
	padding: 4rem;

	@include sm-down {
		padding: 4rem 0;
	}
}

.cta_container {
	margin-top: 2.5rem;

	@include sm-down {
		margin-top: 1.5rem;
	}
}

.quote {
	margin: 1rem 0 0;

	blockquote {
		margin: 0;
	}

	figcaption {
		p {
			&::before {
				content: '–';
			}
		}

		margin-top: 1rem;
	}
}

.author {
	color: $text-grey-light;
}

.eyebrow {
	&[data-accent='true'] {
		color: $text-grey-light;
	}
}
