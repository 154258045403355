@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';

.container {
	&[data-theme='dark'] {
		color: $text-white;
		background-color: $background-black;

		.subheader,
		.eyebrow,
		.benefit_container p {
			color: $text-grey-light;
		}
	}

	&[data-theme='light'] {
		color: $text-black;
		background-color: $background-grey-light;

		.subheader,
		.eyebrow,
		.benefit_container p {
			color: $text-grey-dark;
		}
	}

	&[data-theme='white'] {
		color: $text-black;
		background-color: $background-white;

		.subheader,
		.eyebrow,
		.benefit_container p {
			color: $text-grey-dark;
		}
	}

	@include section-padding;
}

.header {
	text-align: center;
	h2 {
		max-width: 40ch;
		margin-inline: auto;
	}

	.subheader {
		margin-inline: auto;
		text-align: center;
		max-width: 44ch;
	}

	margin-bottom: $spacing-xx-large-desktop;

	@include sm-down {
		margin-bottom: $spacing-x-large-desktop;
	}
}

.header_cta {
	margin-top: $spacing-x-large-desktop;
}

.perfect_temperature {
	max-width: 85rem;

	margin-inline: auto;
}

.main_image_container {
	position: relative;
	width: 100%;
	aspect-ratio: 85 / 45;
}

.main_image {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 0.5rem;

	object-fit: cover;
}

.cool {
	border-radius: 1.5rem;

	position: absolute !important;
	bottom: 6rem;
	left: 6rem;

	@include sm-down {
		bottom: auto;
		top: -3rem;
		left: 0;
		scale: 0.8 !important;
	}
}

.warm {
	border-radius: 1.5rem;

	position: absolute !important;

	top: 6rem;
	right: 6rem;

	@include sm-down {
		top: auto;
		right: 0;
		bottom: -3rem;
		scale: 0.8 !important;
	}
}

.benefit_container {
	text-align: center;

	list-style: none;

	display: grid;
	grid-template-columns: repeat(auto-fit, 28rem);
	gap: 10rem;

	justify-content: center;

	svg {
		display: block;
		margin: auto;
		margin-bottom: 1.25rem;
	}

	h3 {
		margin-inline: auto;
		max-width: 25ch;
	}

	p {
		max-width: 40ch;
		@include sm-down {
			margin: 0 auto;
		}
	}

	margin-top: $spacing-xx-large-desktop;

	@include sm-down {
		grid-template-columns: 1fr;
		gap: $spacing-x-large-desktop;

		border-radius: 0.5rem;

		margin-inline: auto;
		margin-top: $spacing-x-large-desktop;
	}
}

.extra_sleep {
	background-color: $background-black;
	color: $text-white;
	@include section-padding;
}

.extra_sleep_inner {
	max-width: 85rem;
	margin-inline: auto;
}

.science_header {
	h2 {
		max-width: 23ch;
	}

	margin-bottom: 4rem;

	@include sm-down {
		margin-bottom: 2rem;
	}
}

.extra_sleep_cta {
	margin-top: 2.5rem;
}

.description_container {
	@include md-up {
		justify-self: end;
	}
}

.extra_sleep .eyebrow {
	color: #a6a6a6;
}

.graph_grid {
	display: grid;

	align-items: center;

	grid-template-columns: auto 1fr 1fr 3fr 1fr;

	isolation: isolate;

	@include sm-down {
		grid-template-columns: 0 2fr 1fr 3fr 0;
	}
}

.graph_header {
	margin-bottom: 4rem;

	@include sm-down {
		max-width: 40ch;
		margin-bottom: 2rem;
	}
}

.bar {
	height: 4.75rem;
	border-radius: $br-sm;
	overflow: hidden;

	$stripe-color: rgba(255, 255, 255, 0.25);
	$space-color: rgba(255, 255, 255, 0);

	background-image: linear-gradient(45deg, $stripe-color 12.5%, $space-color 12.5%, $space-color 50%, $stripe-color 50%, $stripe-color 62.5%, $space-color 62.5%, $space-color 100%);
	background-size: 11.31px 11.31px;

	margin: 0.75rem 0 0.75rem 2rem;

	position: relative;

	&::after {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 0;
		height: 100%;
		background: linear-gradient(90deg, rgba(28, 60, 129, 1) 0%, rgba(36, 106, 255, 1) 100%);
		opacity: 0;
		transition: opacity 0.5s ease-in-out, width 2s cubic-bezier(0.19, 1, 0.22, 1);
		border-radius: $br-sm;
	}

	@include sm-down {
		height: 2.5rem;
		border-radius: $br-sm;
		margin: 0.75rem 0 0.75rem 0.75rem;
	}
}

.bar_highlighted {
	&::after {
		opacity: 1;
		width: 100%;
	}
}

@mixin graph_label {
	color: $white;

	transition: opacity 0.5s ease-in-out;
}

.time {
	@include graph_label;
	margin-right: 1rem;

	text-align: right;

	@include sm-down {
		display: none;
	}
}

.benefit {
	@include graph_label;
	margin-left: 1rem;

	position: relative;
	z-index: 1;

	grid-column: var(--end-col-desktop);

	@include sm-down {
		grid-column: var(--end-col-mobile);

		text-align: right;
		margin-right: 1rem;
	}
}

.grid_border_right {
	position: relative;
	z-index: -1;

	border-right: dashed thin rgba(255, 255, 255, 0.25);
	width: 100%;
	height: 100%;

	@include sm-down {
		display: none;
	}
}

.grid_border_mobile_left {
	position: relative;
	z-index: -1;

	border-left: dashed thin rgba(255, 255, 255, 0.25);
	width: 100%;
	height: 100%;

	display: none;

	@include sm-down {
		display: unset;
	}
}

.grid_border_bottom {
	position: relative;
	z-index: -1;

	border-bottom: dashed thin rgba(255, 255, 255, 0.25);
	width: 100%;
	height: 100%;
}

.graph_buttons {
	display: flex;
	flex-direction: row-reverse;
	padding: 2.5rem;

	justify-content: flex-end;
	max-width: 25rem;

	gap: 0.25rem;

	@include sm-down {
		display: none;
	}
}

.graph_button {
	padding: 0.25rem 1rem;

	border-radius: 0.5rem;
	opacity: 0.25;
	background: rgba(255, 255, 255, 0.2);

	flex: 1;

	color: $white !important;

	&:hover {
		opacity: 0.75;
	}

	@include sm-down {
		backdrop-filter: blur(9px);
		opacity: 1;

		color: $white !important;

		border-radius: 0.25rem;
	}
}

.graph_button_mobile {
	display: none;

	@include sm-down {
		display: unset;
	}

	margin-left: 1.5rem;

	position: relative;
	z-index: 2;
}

.graph_button_highlighted {
	opacity: 1;
}

.disclaimer {
	color: #717171;

	max-width: 90ch;

	@include sm-down {
		margin-top: 4rem;
	}
}

.benefit_cta_container {
	margin-top: 2rem;

	display: inline-flex;
	flex-direction: column;

	@include md-up {
		flex-direction: row;
	}

	gap: 1.5rem;

	.arrow_cta {
	}
}
