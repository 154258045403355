@import 'styles/constants';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts_legacy';
@import 'styles/colors-2024';
@import 'styles/layout-tokens';

.modal_wrapper_content {
	background: #00030c;
	position: relative;
	color: #000;
	overflow-y: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;
	width: 100%;
	height: 100%;
	align-items: stretch;
	justify-content: flex-end;

	@include sm-down {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
	}
}

.ecap_image_wrapper {
	position: absolute;
	left: 0px;
	overflow: hidden;
	height: 100%;
	width: 100%;

	@include sm-down {
		position: relative;
		height: 351px;
		flex-shrink: 0;
	}

	@include xs-only {
		position: relative;
		height: 242px;
		flex-shrink: 0;
	}
}

.ecap_image {
	max-height: 100dvh;

	> img {
		height: 100%;
		overflow: visible;
		transition: transform 0.5s ease-out;
		transform-origin: bottom;
	}

	@include md-up {
		> img {
			// image starts zoomed in
			transform: scale(1.3) translate(-10%, 20%);
		}
	}

	@include sm-down {
		> img {
			transform: scale(1) translate(0%, 0%);
			transform-origin: top;
		}
	}
}

.ecap_image_email_step {
	> img {
		// zoom the image out
		transform: scale(1) translate(-20%, 0%);
	}

	@include sm-down {
		> img {
			transform: scale(1.5) translate(5%, 20%);
		}
	}
}

.ecap_image_phone_step {
	> img {
		// zoom the image out
		transform: scale(1) translate(-20%, 0%);
	}

	@include sm-down {
		> img {
			transform: scale(1.5) translate(5%, 20%);
		}
	}
}

.ecap_image_complete_step {
	> img {
		// zoom the image in
		transform: scale(1.3) translate(-10%, 20%);
	}

	@include sm-down {
		> img {
			transform: scale(1) translate(0%, 0%);
		}
	}
}

.close_button {
	background: none;
	outline: none;
	position: absolute !important;
	top: 35px;
	right: 35px;
	width: 32px;
	height: 32px;
	z-index: 100;
	border: none;
	padding: 0;
	cursor: pointer;

	& span > svg {
		width: 100%;
		height: 100%;
	}

	@include sm-down {
		top: 20px;
		right: 20px;
	}
}
