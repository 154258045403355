@import 'styles/constants';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	display: grid;
	grid-template-columns: 1fr 2fr;
	column-gap: 0;

	.img_container {
		padding: 0;

		img {
			border-radius: 25px;

			@include sm-down {
				border-radius: 10px;
			}
		}

		@include sm-down {
			padding: 0 10px;
		}
	}

	@include sm-down {
		grid-template-columns: 1fr;
	}

	.wrapper {
		display: flex;
		flex-direction: column;
		padding: 55px;
		justify-content: center;
		max-width: 900px;
		order: 1;
		align-self: flex-start;
		margin-top: 2rem;
		gap: 24px;

		border-radius: 25px;
		background: rgba(20, 20, 20, 0.82);
		backdrop-filter: blur(30px);
		margin-left: -100px;

		@include sm-down {
			margin-left: 0;
			margin-top: 0;
			padding: 24px;
			background: transparent;
			backdrop-filter: none;
		}
	}
}
