@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';

.container {
	background-color: $black;
	color: $white;

	position: relative;
}

.canvas_rails {
	position: absolute;

	top: 0;
	left: 0;

	height: 100%;
	width: 100%;

	z-index: 0;

	pointer-events: none;

	* {
		pointer-events: none;
	}
}

.canvas_container {
	position: sticky;
	top: 0;

	height: 100svh;
}

.hero {
	min-height: 100svh;
	display: flex;
	justify-content: center;
	align-items: center;

	position: relative;
	z-index: 2;
	pointer-events: none;

	background: radial-gradient(closest-side, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}

.hero header {
	text-align: center;

	h1 {
		max-width: 15ch;
	}
}

.blueprint {
	padding: 10rem 4rem;

	position: relative;
	z-index: 2;

	max-width: 1440px;
	margin-inline: auto;

	@include sm-down {
		padding: 3.75rem 1.5rem;
	}
}

.blueprint header {
	display: grid;
	grid-template-columns: 1fr 1fr;

	gap: $spacing-md;

	position: relative;
	z-index: 2;

	h2 {
		max-width: 12ch;
	}

	p {
		opacity: 0.6;

		max-width: 24ch;

		margin: 0 !important;
	}

	@include sm-down {
		grid-template-columns: 1fr;
		grid-template-rows: auto 1fr;
	}
}

.benefits {
	margin: 0;
	padding: 0;
	list-style: none;

	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(2, 1fr);

	justify-items: center;
	gap: 4rem;

	margin-block: 12rem;

	@include xs-only {
		grid-template-columns: 1fr;
		grid-template-rows: unset;

		margin-block: 4rem 0;
		gap: 0;
	}
}

.benefit {
	aspect-ratio: 1 / 1;

	width: 12rem;
	height: 12rem;
	padding: $spacing-md;

	border-radius: 0.875rem;

	background: rgba(20, 20, 20, 0.4);

	border: 1px solid #634b66;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	text-align: center;

	gap: $spacing-sm;

	position: relative;
	z-index: 1;

	transition: all 0.25s ease-out;

	//backdrop-filter: blur(8.5px);
	scale: 0.95;

	&:hover {
		scale: 1;
	}

	svg {
		width: clamp(1.5rem, 5vw, 4rem);
		height: clamp(1.5rem, 5vw, 4rem);
	}

	@include sm-down {
		width: 8rem;
		height: 8rem;
		padding: $spacing-sm;
	}
}

.benefits li:nth-child(1) {
	.benefit {
		transform: translate3d(90%, 40%, 0);

		@include xs-only {
			transform: translate3d(-40%, 40%, 0);
		}
	}
}

.benefits li:nth-child(2) {
	.benefit {
		transform: translate3d(0%, -40%, 0);

		@include xs-only {
			transform: translate3d(28%, 0%, 0);
		}
	}
}

.benefits li:nth-child(3) {
	.benefit {
		transform: translate3d(-80%, 70%, 0);

		@include xs-only {
			transform: translate3d(-70%, 40%, 0);
		}
	}
}

.benefits li:nth-child(4) {
	.benefit {
		transform: translate3d(0%, 20%, 0);

		@include xs-only {
			transform: translate3d(55%, 20%, 0);
		}
	}
}

.benefits li:nth-child(5) {
	.benefit {
		transform: translate3d(-40%, 52%, 0);

		@include xs-only {
			transform: translate3d(-40%, -10%, 0);
		}
	}
}

.benefits li:nth-child(6) {
	.benefit {
		transform: translate3d(-30%, 120%, 0);

		@include xs-only {
			transform: translate3d(60%, 30%, 0);
		}
	}
}
