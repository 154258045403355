@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-decorated';

.container {
	color: $white;
	overflow: hidden;
	background: radial-gradient(ellipse at 50% 60%, rgb(37, 37, 37) 0%, rgb(29, 29, 29) 30%, hsl(0, 0%, 0%) 99%);

	@include sm-down {
		background: radial-gradient(ellipse at 50% 60%, rgb(37, 37, 37) 0%, rgb(29, 29, 29) 30%, hsl(0, 0%, 0%) 99%);
	}
}

.header {
	padding: $spacing-xl $spacing-sm 0 $spacing-sm;

	text-align: center;

	margin-bottom: $spacing-md;
}

.subtitle {
	opacity: 0.5 !important;
	text-wrap: balance;
}

.header h2 {
	max-width: 20ch;
	margin-inline: auto;

	margin-top: $spacing-small-desktop !important;
}

.benefits_wrapper {
	margin: 0 auto;
	display: flex;
	justify-content: center;
}

.benefits {
	margin: 0;
	display: flex;
	justify-content: center;
	gap: 2rem;
	list-style: none;

	@include sm-down {
		flex-direction: column;
		gap: 1rem;

		width: 100%;
		max-width: 40rem;
	}

	padding: 0 0 $spacing-xl;
}

.bottom_text {
	padding: 0 $spacing-sm $spacing-xl;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	max-width: 570px;

	p {
		margin-inline: auto;
		text-align: center;
		color: $black;
	}

	em {
		opacity: 0.5;
		font-style: normal;
	}
}

.top_content {
	position: relative;
	z-index: 1;
}

.bottom_container {
	position: relative;
	z-index: 1;

	background-color: $white;
	color: $black;

	padding: 15vh 4rem 0;

	@include sm-down {
		padding: 4rem 1.25rem 0;
	}
}

.benefit {
	text-align: center;
	border-radius: 0.5rem;
	background: $background-off-white;
	padding: 34px;

	svg {
		width: 2rem;
		height: 2rem;
		margin-bottom: 1rem;
	}

	color: $black;

	@include sm-down {
		border-radius: 10px;
		width: 100%;
	}
}

.benefit h3 {
	max-width: 10ch;
	margin-inline: auto;
}

.benefit p {
	max-width: 28ch;
	color: rgba(0, 0, 0, 0.5);
}

.top_cta {
	display: flex;
	// grid-template-columns: repeat(2, minmax(0, 1fr));
	justify-content: center;
	align-items: center;
	gap: 1rem;

	padding-bottom: 2rem;

	@include xs-only {
		grid-template-columns: 1fr;
		justify-items: center;
	}

	> a,
	> div {
		flex: 0 1 auto;
	}
}

.cta_container {
	display: flex;
	justify-content: center;
}

.affirm_text {
	flex: 0;

	svg {
		max-height: 1.25em;
		display: inline;
	}

	span {
		display: inline-block;
		height: 1.25em;
		width: 3em;
	}
}

.cropped_image_container {
	display: flex;
	justify-content: center;
	overflow: visible;
	max-height: 800px;
	height: 60vh;
	margin-bottom: 5vh;
}

.cropped_image {
	img {
		height: 80vh;
		width: auto;
		max-height: 1000px;
		object-position: top center;
		z-index: 5;
		position: relative;

		transform: translateX(1.5%);
	}
}

.bottom_cta_container {
	display: flex;
	justify-content: center;
	align-items: center;

	padding: 4rem 0 2rem;

	@include md-up {
		display: none;
	}
}
