@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';
@import 'styles/mixins/fonts-decorated';

.container {
	background-color: $background-white;
	color: $text-black;

	&[data-theme='light'] {
		background-color: $background-off-white;
	}

	text-align: center;

	@include section-padding;
}

.container header {
	text-align: center;

	h2 {
		max-width: 20ch;
		margin-inline: auto;
	}

	p {
		max-width: 45ch;
		margin-inline: auto;
	}
}

.benefits {
	list-style: none;

	max-width: 60rem;
	margin: 0 auto;
	padding: 0;

	display: grid;
	grid-template-columns: repeat(3, 1fr);
	margin-block: 4rem;
	gap: 2rem;

	@include sm-down {
		grid-template-columns: repeat(2, 1fr);

		li {
			&:last-child {
				display: none;
			}
		}
	}
}

.benefit {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	gap: 1rem;

	svg {
		width: 2.25rem;
		height: 2.25rem;
	}

	p {
		max-width: 13ch;
		text-align: left;
	}
}

.video_container {
	aspect-ratio: 16 / 9;

	position: relative;

	max-height: 668px;
	max-width: 90rem;

	margin: 0 auto;
	margin-block-start: 4rem;

	video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 1rem;
	}
}

.cta_container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	display: flex;
	justify-content: center;
	align-items: center;

	margin: auto;
}

.fullVideo {
	object-fit: cover;
	height: 100%;
	width: 100%;
}

.videoContainer {
	background-color: $white;
	display: flex;
	justify-content: center;
	align-items: center;

	height: 100%;
	width: 100%;

	video {
		object-fit: cover;
		height: 100%;
		width: 100%;
	}
}

.cta {
	@include sm-down {
		margin-top: 2rem;
	}
}

.video_button {
	color: $white !important;

	padding: 1rem !important;
	border-radius: 0.5rem !important;
	background: rgba(242, 242, 242, 0.2);
	backdrop-filter: blur(12px);

	@include sm-up {
		background: rgba(242, 242, 242, 0.2);
		backdrop-filter: blur(12px);
	}

	span {
		display: flex;
		align-items: center;
		gap: 0.5rem;
		@include button1;

		@include sm-down {
			font-size: 1rem;
		}
		color: $white !important;
	}

	svg {
		width: 2rem;
		height: 2rem;
	}

	&:hover,
	&:focus-visible {
		background: transparentize(white, 0.7);
	}
}
