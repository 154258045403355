@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2023';
@import 'styles/colors-2023';
@import 'styles/mixins/button-styles-2023';
@import 'styles/mixins/fonts-decorated';

.container {
	position: fixed;
	top: 0;
	width: 100%;
	padding: 2rem 4rem 0;

	z-index: 10;

	transition: transform 0.5s cubic-bezier(0.65, 0, 0.35, 1), visibility 0.5s cubic-bezier(0.65, 0, 0.35, 1);

	@include md-up {
		transform: translateY(-100%) scale(0.8);
	}

	@include sm-down {
		top: auto;
		bottom: 0;
		padding: 0;
		width: 100vw;
		transform: translateY(100%);
	}

	visibility: hidden;
}

.container_visible {
	transform: none;
	visibility: visible;
}

.inner {
	background-color: $neutral-white;
	color: $neutral-black;

	border: 1px solid rgba(205, 205, 211, 0.1);

	box-shadow: -0.1px 0.2px 0.3px rgba(148, 148, 148, 0.04), -0.8px 1.5px 1.9px -0.4px rgba(148, 148, 148, 0.04), -1.5px 2.9px 3.7px -0.8px rgba(148, 148, 148, 0.04),
		-2.7px 5.1px 6.5px -1.2px rgba(148, 148, 148, 0.04), -4.6px 8.7px 11.1px -1.7px rgba(148, 148, 148, 0.04), -7.5px 14.3px 18.2px -2.1px rgba(148, 148, 148, 0.04),
		-11.8px 22.5px 28.6px -2.5px rgba(148, 148, 148, 0.04);

	display: grid;
	grid-template-columns: 1fr auto;
	grid-template-rows: 1fr;
	column-gap: 1rem;
	row-gap: 0.25rem;
	align-items: center;

	h2 {
		@include subheading3;
		font-weight: 500;
	}

	padding: 1.25rem;

	@include md-up {
		padding: 1rem 2rem;

		border-radius: 6px;
		grid-template-columns: 1fr auto auto;
		column-gap: 0;

		h2 {
			@include subheading3;
			font-weight: 500;
		}
	}
}

.top_line {
	@include subheading5;
	grid-row: 2;

	s {
		color: rgba(121, 124, 142, 1);
		font-weight: 400;
	}

	span {
		font-weight: 400;
	}

	@include md-up {
		@include subheading4;

		grid-row: unset;
	}
}

.bottom_line {
	@include body7;
	color: $neutral-3;
}

.pricing_desktop {
	text-align: right;
	margin-right: 2rem;

	display: none;
	@include md-up {
		display: block;
	}
}

.mobile_button {
	white-space: nowrap;
	@include md-up {
		display: none !important;
	}
}

.desktop_button {
	position: relative;
	@include sm-down {
		display: none !important;
	}
}

.pricing_mobile {
	@include md-up {
		display: none;
	}
}

.mobile_financing {
	display: inline-block;
	@include body6;
}

.discount_mobile {
	display: block;
	color: $solid-blue;
	@include subheading6;
	white-space: nowrap;
	margin-top: 0.25rem;

	font-weight: 500;
}

.sticky_title_mobile {
	@include md-up {
		display: none;
	}
}

.sticky_title_and_discount {
	display: flex;
	flex-wrap: wrap;
	column-gap: 1rem;
	row-gap: 0.25rem;

	align-items: center;
}

.financing_content {
	margin-top: 5px;

	> p {
		color: #999;
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: 140%; /* 16.8px */
		letter-spacing: 0.12px;
		margin: 0;

		display: flex;
		align-items: center;
		gap: 5px;

		svg {
			display: block;
			width: 18px;
			height: 18px;
		}

		span {
			align-self: flex-end;

			> span {
				text-transform: capitalize;
			}
		}
	}

	display: flex;
	gap: 16px;

	@include sm-down {
		flex-direction: column;
		gap: 2px;
	}
}

.financing_item {
	@include xs-only {
		display: none !important;
	}
}
