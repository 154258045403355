@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';
@import 'styles/mixins/fonts-decorated';

.container {
	background-color: $background-black;
	color: $white;

	@include section_padding;
}

.container > header {
	text-align: center;
	p {
		color: $text-grey-light;

		max-width: 60ch;
		margin-inline: auto;
	}
	margin-bottom: 4rem;
}

.tabs {
	display: flex;
	flex-direction: row;
	gap: 2.5rem;

	margin-top: 4rem;

	@include sm-down {
		gap: 0;
		flex-direction: column;
		margin-top: 2rem;
	}

	justify-content: center;

	> button > span {
		width: 100%;
	}
}

.tab {
	position: relative;
	margin-top: -1px;
	border-top: 1px solid rgba(217, 217, 217, 0.2);
	padding: 1.5rem 0;

	display: block;

	color: $white;

	//cursor: pointer;
	width: 190px;

	text-align: left;

	transition: all 0.25s ease-out;

	@include sm-down {
		padding-top: 1rem;
		padding-bottom: 2rem;
		width: 70%;
	}

	z-index: 0;
}

.tab_progress {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;

	width: 100%;
	height: 1px;
	background-color: $white;

	transition: opacity 0.25s ease-out;
	z-index: 1;

	opacity: 0;
}

.tabSelected {
	position: absolute;
	top: 0;
	left: 0;
	min-width: 60%;
	margin-top: -1px;
	border-top: 1px solid $white;
	z-index: 1;
}

.tabContainer {
	max-width: 60rem;

	margin-inline: auto;

	width: 100%;
}

.media_container {
	position: relative;

	width: 100%;
}

.slide {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.slide > svg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	z-index: 1;

	opacity: 0;

	transition: all 0.25s ease-out;
}

.slide_selected > svg,
.slide_selected .warm_dial,
.slide_selected .cool_dial {
	opacity: 1 !important;
}

.video_container {
	position: relative;

	max-height: 40rem;
	height: 40vw;
	width: 100%;

	margin-bottom: 4rem;

	@include sm-down {
		height: 22rem;
		margin-bottom: 2rem;
	}
}

.media {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	object-fit: cover;

	border-radius: $br-md;

	video {
		border-radius: $br-md;

		object-fit: cover;
		@include sm-down {
			aspect-ratio: 1;
			min-height: 15rem;

			max-height: 30rem;
		}
	}
}

.pitch {
	display: flex;
	gap: 1.5rem;
	flex-direction: column;
	align-items: center;

	@include sm-down {
		gap: 0.75rem;
	}
}

.warm_dial,
.cool_dial {
	position: absolute;
	bottom: 30%;
	right: 8%;

	z-index: 10;

	opacity: 0;

	@include sm-down {
		scale: 0.8;

		bottom: 10%;
		right: 10%;
	}

	transition: opacity 0.25s ease-out;
}
