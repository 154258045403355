@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2023';
@import 'styles/colors-2023';

.container {
	max-width: 1286px;
	margin-inline: auto;
	background-color: white;
	color: $neutral-black;
	display: flex;
	justify-content: center;
	column-gap: 30px;

	@include sm-down {
		flex-direction: column;
		row-gap: 30px;
	}
}

.inner {
	background-color: $neutral-8;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 120px 60px;
	text-align: center;

	@include sm-down {
		padding: 60px 30px;
	}
}

.text_container {
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	h2 {
		@include subheading2;
		margin-bottom: 1rem;
	}

	strong {
		display: inline-block;
		@include body6;

		color: $neutral-2;
		margin-bottom: 1rem;

		a {
			color: $neutral-2;
			text-decoration: underline;
			cursor: pointer;
		}
	}

	p {
		@include body5;
		max-width: 63ch;
		text-align: center;
	}

	@include md-up {
		text-align: center;

		h2 {
			@include subheading1;
			margin-bottom: 1rem;
		}

		strong {
			@include body6;
			margin-bottom: 1rem;
		}

		p {
			@include body5;
			min-height: 95px;
		}
	}
}

.logo_container {
	width: 100%;
	height: 100%;

	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 20px;

	svg {
		max-height: 60px;
		width: 60px;
	}
}

.learn_more {
	margin-top: 15px;
}
