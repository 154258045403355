@import 'styles/constants';
@import 'styles/colors';

@mixin buttonlink {
	color: #fff;
	background-color: $brand-primary;
	border: 3px solid $brand-primary;
	cursor: pointer;
	padding: 16px 24px;
	position: relative;
	font-family: var(--neue-montreal), sans-serif;
	text-decoration: none;
	text-align: center;
	text-transform: var(--button-text-transform);
	letter-spacing: 0.5px;
	background-position: center;
	white-space: nowrap;
	height: 52px;
	font-weight: 500;
	font-size: 16px;
	border-radius: 3px;
	line-height: 15px;
	transition: all 0.15s;

	display: inline-flex;
	justify-content: center;
	align-items: center;

	&:hover {
		background-color: $brand-secondary;
		border-color: $brand-secondary;
		color: #fff;
	}

	&:active {
		background-color: $brand-secondary;
		border-color: $brand-secondary;
	}
	@include sm-down {
		margin-left: auto;
		margin-right: auto;
	}
	@include md-up {
		width: auto;
	}
}

@mixin buttonAnchorLink {
	color: #fff;
	background-color: #000;
	border: 3px solid #000;
	cursor: pointer;
	padding: 16px 24px;
	position: relative;
	font-family: var(--neue-montreal), sans-serif;
	text-decoration: none;
	text-align: center;
	letter-spacing: 0.5px;
	background-position: center;
	white-space: nowrap;
	height: 52px;
	font-weight: 500;
	font-size: 16px;
	border-radius: 3px;
	line-height: 15px;
	transition: all 0.15s;

	display: inline-flex;
	justify-content: center;
	align-items: center;

	&:hover {
		background-color: $brand-secondary;
		border-color: $brand-secondary;
		color: #fff;
		text-decoration: none;
	}

	&:active {
		background-color: $brand-secondary;
		border-color: $brand-secondary;
		color: #fff;
	}
}

@mixin button-secondary {
	color: $ui-black;
	background-color: $ui-white;
	border: 3px solid $ui-white;
	cursor: pointer;
	padding: 16px 24px;
	position: relative;
	font-family: var(--neue-montreal), sans-serif;
	text-decoration: none;
	text-align: center;
	text-transform: var(--button-text-transform);
	letter-spacing: 0.5px;
	background-position: center;
	white-space: nowrap;
	height: 52px;
	font-weight: 500;
	font-size: 16px;
	border-radius: 3px;
	line-height: 15px;
	transition: all 0.15s;

	display: inline-flex;
	justify-content: center;
	align-items: center;

	&:hover {
		background-color: $brand-primary;
		border-color: $brand-primary;
		color: $ui-white;
		text-decoration: none;
	}

	&:active {
		background-color: $brand-primary;
		border-color: $brand-primary;
	}
}

@mixin btn-white {
	@include button-secondary;
	min-width: 245px;

	@include xs-only {
		position: static;
	}
}
