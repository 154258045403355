@mixin text {
	font-family: var(--neue-montreal), sans-serif;
	margin-top: 0;
	margin-bottom: 0;
	letter-spacing: 0;
	font-style: normal;
}

$header-base-line-height: 120%;
$subheader-base-line-height: 130%;
$body-base-line-height: 150%;

@mixin header {
	@include text;
	font-weight: bold;
}

@mixin subheader {
	@include text;
	font-weight: 700;
	line-height: $subheader-base-line-height;
}

@mixin body {
	@include text;
	font-weight: 400;
	line-height: $body-base-line-height;
}

@mixin heading1 {
	@include header;
	font-size: 7.5rem;
	line-height: 100%;
}

@mixin heading2 {
	@include header;
	font-size: 5rem;
	line-height: 110%;
}

@mixin heading3 {
	@include header;
	font-size: 4.5rem;
	line-height: 110%;
}

@mixin heading4 {
	@include header;
	font-size: 3.5rem;
	line-height: 110%;
}

@mixin heading5 {
	@include header;
	font-size: 2.5rem;
	line-height: 120%;
}

@mixin subheading1 {
	@include subheader;
	font-size: 2rem;
}

@mixin subheading2 {
	@include subheader;
	font-size: 1.5rem;
}

@mixin subheading3 {
	@include subheader;
	font-size: 1.25rem;
}

@mixin subheading4 {
	@include subheader;
	font-size: 1rem;
	line-height: 140%;
}

@mixin subheading5 {
	@include subheader;
	font-size: 0.875rem;
	line-height: 140%;
}

@mixin subheading6 {
	@include subheader;
	font-size: 0.75rem;
	line-height: 140%;
}

@mixin body1 {
	@include body;
	font-size: 2rem;
	line-height: 130%;
}

@mixin body2 {
	@include body;
	font-size: 1.5rem;
	line-height: 130%;
}

@mixin body3 {
	@include body;
	font-size: 1.25rem;
	line-height: 130%;
}

@mixin body4 {
	@include body;
	font-size: 1.125rem;
}

@mixin body5 {
	@include body;
	font-size: 1rem;
}

@mixin body6 {
	@include body;
	font-size: 0.875rem;
}

@mixin body7 {
	@include body;
	font-size: 0.75rem;
}

@mixin body8 {
	@include body;
	font-size: 0.625rem;
}
