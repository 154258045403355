.img {
	position: relative;
	display: block;
	width: 100%;
	max-height: 100%;
	font-size: 0;
	//content: '';
}

.img_background {
	width: 100%;
	position: relative;
}
