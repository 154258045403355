/*

	Color Update Spring 2022
	Based on Web Design Library 2022 Figma
	https://www.figma.com/file/N2wo4yXJqDqjtfNIVXha2h/Web-Design-Library-2022?node-id=193%3A2560

 */

/* UI Colors */

$ui-white: #fff;

$ui-black: #000;

$ui-dark-grey: #1b1b20;

$ui-mid-grey: #f2f4f6;

$ui-light-grey: #f7f8fa;

$ui-velvet-red: #b91332;

$ui-velvet-red-shade: #fde8ec;

$blue-tertiary: #8aafff;

$blue-secondary: #0047e0;

$blue-primary: #1862ff;

$ui-bfcm-primary: #c1a87d;

$ui-bfcm-accent: #b58d3d;

$ui-bfcm-secondary: #edddb8;

$ui-holiday-blue: #002173;

$ui-holiday-blue-secondary: #3461d7;

/* Typography Colors */

$type-blue1: #444652;
$type-blue2: #555b6c;
$type-blue3: #797c8e;
$type-blue4: #adb3c5;

/* Temperature Colors */

$temp-extremely-cold: #1862ff;
$temp-very-cold: #304bf6;
$temp-cold: #3744f3;
$temp-cool: #4c39f2;
$temp-neutral: #832ef5;
$temp-warm: #b91332;
$temp-hot: #c31435;
$temp-very-hot: #d01639;
$temp-extremely-hot: #e6183f;
$temp-gradient: linear-gradient(90deg, $temp-extremely-cold 0%, $temp-cool 41.95%, $temp-neutral 48.49%, $temp-warm 56.29%, $temp-extremely-hot 100%);
$pod-3-gradient: radial-gradient(54.78% 52.83% at 50% 35.29%, #bc50ff 0%, #6171ff 98.74%);

$error: $ui-velvet-red;
$error-border: $ui-velvet-red;
$error-background: $ui-velvet-red-shade;

$bfcm-2023-bg: linear-gradient(91deg, #014ea8 -14.01%, #013b8b 53.53%, #012a6d 115.67%);
$bfcm-2023-blue: #0249a0;

// main theme variables
$brand-primary: $bfcm-2023-blue;
$brand-secondary: $ui-holiday-blue;
$brand-primary-light: $blue-tertiary;
