@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

$block-padding: 2rem;
$inline-padding: 1.5rem;

$block-padding-mobile: 1.25rem;
$inline-padding-mobile: 1rem;

.glass {
	border-radius: 0.5rem;

	text-align: center;

	position: relative;

	padding: $block-padding $inline-padding;

	width: 13rem;
	height: 14rem;

	@include sm-down {
		padding: $block-padding-mobile $inline-padding-mobile;
		width: 8.75rem;
		height: 9.375rem;
	}
}

.temperature {
	position: absolute;
	top: 5.5rem;
	left: 0;
	right: 0;

	@include headline5;

	@include sm-down {
		top: 3.5rem;
		@include headline6;
		font-weight: 400;
	}
}

.side {
	position: absolute;
	left: 0;
	right: 0;
	bottom: $block-padding;
	padding: 0 1rem;

	color: $white;

	@include body5;

	@include sm-down {
		@include body6;

		bottom: $block-padding-mobile;
	}
}

.glass .cool_pane,
.glass .warm_pane {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 0.5rem;

	transition: 0.25s ease-out;
}

.cool_pane {
	background: linear-gradient(180deg, #2d36b359 0%, #777aa759 100%);
}

.warm_pane {
	background: linear-gradient(180deg, #ac323459 0%, #81656559 100%);
}

.inner_circle {
	transform-origin: center;
	transform: rotate(90deg);
}

.degrees {
	margin-left: 0.1ch;
	letter-spacing: -0.1ch;
}

.glass svg {
	position: absolute;
	top: $block-padding;
	left: $inline-padding;
	right: $inline-padding;

	@include sm-down {
		top: $block-padding-mobile;
		left: $inline-padding-mobile;
		right: $inline-padding-mobile;
	}

	stop {
		transition: 0.25s ease-out;
	}
}
