@import 'styles/mixins/fonts';
@import 'styles/colors';
@import 'styles/mixins/media-queries';

.tonal_modal {
	align-items: flex-end;

	margin: 0 0 10px 0;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;

	.ccc {
		text-decoration: underline;
		display: inline-block;
		margin-left: 5px;

		button {
			font-size: 12px;
			color: #797c8e;
			margin-top: 0;
			margin-bottom: 0;
			line-height: 1;

			> picture {
				top: -2px;
				left: 1px;
			}
		}
	}
}

.trigger {
	text-decoration: underline;
	background: none;
	border: none;
	font-size: 18px;
	font-weight: bold;
	display: inherit;
	cursor: pointer;
	padding: 1px 0.4ch;
	color: black;
}

.tooltip_icon {
	position: relative;
	left: 5px;
	vertical-align: middle;
	display: inline-flex;

	filter: saturate(0);
	opacity: 0.7;

	img {
		width: 14px;
		height: 14px;
	}
}

.content {
	background-color: #fff;
	max-width: calc(100vw - 40px);

	width: 900px;
	padding: 60px;
	position: relative;

	z-index: 1000;

	@include xs-only {
		padding: 30px;
		padding-top: 60px;
	}

	display: flex;
	flex-direction: column;

	border-radius: 5px;

	transition: all 0.4s ease;
}

.modal_h {
	@include sm-down {
		@include h4-mobile;
	}

	@include xs-only {
		max-width: 240px;
		margin-bottom: 0;
		padding: 0;
	}

	@include h3-desktop;
	margin-bottom: 30px;
}

.modal_content_container {
	background-color: #fff;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	overflow-y: auto;
	margin: 0 20px;
	line-height: 1.4;
	box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.15);
	border-radius: 10px;
}

.close_button {
	position: absolute;
	top: 30px;
	right: 30px;
	z-index: 10000;
	cursor: pointer;
	border: none;
	background-color: rgb(255 255 255 / 75%);
	border-radius: 100px;
	width: 24px;
	height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		width: 12px;
	}

	@include xs-only {
		top: 15px;
		right: 15px;
	}
}

.table {
	text-align: center;
	font-size: 12px;

	@include xs-only {
		tr.tablike {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;

			> th {
				text-align: left;
				vertical-align: top;
				font-size: 1em;
				padding: 15px 10px 10px 10px;
				border-bottom: 2px solid rgba(102, 102, 102, 0.08);
				min-width: 90px;

				&:first-of-type {
					padding: 15px 0 10px 0;
				}

				&.active {
					border-color: #4c39f2;
				}
			}
		}

		tr:not(.tablike) {
			display: grid;
			grid-template-columns: 1fr 1fr;
		}

		td {
			display: none;
		}

		td:first-of-type {
			display: flex;
			align-items: center;
		}

		&.show_column_3 td:nth-of-type(4) {
			display: block;
			min-height: 45px;
		}
		&.show_column_2 td:nth-of-type(3) {
			display: block;
			min-height: 45px;
		}
		&.show_column_1 td:nth-of-type(2) {
			display: block;
			min-height: 45px;
		}
	}

	> table {
		width: 100%;
		table-layout: fixed;
	}

	td,
	th {
		padding: 10px 0;
	}

	th {
		font-size: 1.2em;
	}

	td {
		color: #797c8e;
	}

	img {
		max-width: 20px;
		margin: 0 auto;
	}

	.t_left {
		text-align: left;
		padding: 15px 0;
		color: #000;
		width: 300px;

		@include xs-only {
			width: auto;
			padding: 7px 0;
		}
	}
}
