@import 'styles/constants';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts_legacy';

.container {
	background-color: rgba(0, 0, 0, 0.7);
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	overflow: auto;
	position: fixed;
	height: 100dvh;
	width: 100vw;
	z-index: 1300;

	transition: opacity 0.75s ease-out, transform 0.75s ease-out;

	&.none {
		opacity: 0;
		pointer-events: none;
	}

	@include max-height(500px) {
		align-items: flex-start;
	}
}

.inner {
	@include sm-up {
		position: relative;
	}
	@include md-up {
		opacity: 1;
		display: flex;
		width: 100%;
	}
}

.content {
	background-color: #fff;
	width: 319px;
	position: relative;
	display: flex;
	height: auto;
	overflow-y: auto;
	width: 90%;
	@include minmax(450px, 599px) {
		overflow: scroll;
	}

	@include sm-up {
		display: flex;
		overflow-x: hidden;
		overflow-y: auto;
		width: 360px;
		height: auto;
		padding: 0;
	}

	@include md-up {
		width: 360px;
		height: auto;
		padding: 0;
		display: flex;
	}
	p {
		color: #000;
		font-family: var(--neue-montreal), sans-serif;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 18px;
		margin-top: 0;
	}
}

.inner_text {
	padding: 48px 18px 26px 18px;
	color: #000;
	@include sm-up {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 68px 32px 32px 32px;
	}
	@include md-up {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 68px 32px 32px 32px;
	}
}

.close_button {
	position: absolute;
	top: 25px;
	right: 25px;
	z-index: 100;
	color: #000;
	width: 18px;
	cursor: pointer;
}

.email_validation_error {
	font-family: var(--neue-montreal), sans-serif !important;
	letter-spacing: 1px !important;
	width: 100% !important;
	font-weight: 400 !important;
	color: #b50000 !important;
	font-size: 13px !important;
	margin-bottom: 10px !important;
	margin-top: 0 !important;
}

.phone_validation_error {
	font-family: var(--neue-montreal), sans-serif !important;
	letter-spacing: 1px !important;
	font-weight: 400 !important;
	color: #b50000 !important;
	font-size: 13px !important;
	margin: 0px;
}
