@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	background-color: $black;
	color: $white;

	min-height: 100vh;

	position: relative;

	padding: 12rem 4rem 0;

	text-align: center;
	display: flex;
	flex-direction: column;

	align-items: center;

	@include sm-down {
		padding: 6rem 2rem 0;
	}

	isolation: isolate;
}

.container h2 {
	@include headline3;

	margin-bottom: 2rem;
	@include sm-down {
		@include headline5;

		margin-bottom: 1.5rem;
	}
}

.container p {
	@include body1;
	color: rgba(255, 255, 255, 0.7);
}

.container p a {
	display: block;
	color: $white;
	margin: 2rem auto;

	@include sm-down {
		margin: 1.5rem auto;
	}
}

.container img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	z-index: -1;
}
