@import 'styles/animations';

.c_loading {
	position: relative;

	&::before {
		position: absolute;
		right: 0;
		left: 0;
		display: inline-block;
		width: var(--width);
		height: var(--width);
		margin: 0 auto;
		border: var(--stroke) solid var(--big-color);
		border-top: var(--stroke) solid var(--small-color);
		border-radius: 50%;
		content: ' ';
	}

	&:global(.loading)::before {
		animation: spin var(--speed) linear infinite;
	}
}
