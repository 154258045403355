$font-family: var(--neue-montreal), sans-serif;

@mixin text {
	font-family: $font-family;
	margin-top: 0;
	margin-bottom: 0;
	letter-spacing: 0;
	font-style: normal;
}

@mixin bold {
	@include text;
	font-weight: 700;
}

@mixin medium {
	@include text;
	font-weight: 500;
}

@mixin regular {
	@include text;
	font-weight: 400;
}

/**
  * Headlines
  */

@mixin headline1 {
	@include regular;
	font-size: 10rem;

	line-height: 0.94;
	letter-spacing: -0.2rem;
}

@mixin headline2 {
	@include regular;

	font-size: 6.25rem;
	line-height: 1;
}

@mixin headline3 {
	@include regular;

	font-size: 4rem;
	line-height: 1.1;

	letter-spacing: 0.04rem;
}

@mixin headline4 {
	@include regular;

	font-size: 3rem;
	line-height: normal;

	letter-spacing: 0.03rem;
}

@mixin headline5 {
	@include regular;

	font-size: 2.5rem;
	line-height: 1.1;
}

@mixin headline6 {
	@include medium;

	font-size: 2rem;
	line-height: 1.25;

	letter-spacing: 0.02rem;
}

@mixin headline7 {
	@include medium;

	font-size: 1.5rem;
	line-height: 1.25;

	letter-spacing: 0.015rem;
}

@mixin headline8 {
	@include medium;

	font-size: 1.25rem;
	line-height: 1.4;

	letter-spacing: 0.0125rem;
}

@mixin headline9 {
	@include medium;

	font-size: 1.125rem;
	line-height: 1.4;

	letter-spacing: 0.01125rem;
}

@mixin headline10 {
	@include medium;

	font-size: 0.875rem;
	line-height: 1.1;

	letter-spacing: 0;
}

/**
  * Specials
  */

@mixin eyebrow1 {
	@include medium;

	text-transform: uppercase;

	font-size: 0.875rem;
	line-height: normal;
	letter-spacing: 0.125rem;
}

@mixin eyebrow2 {
	@include medium;

	text-transform: uppercase;

	font-size: 0.75rem;
	line-height: 1.4;
	letter-spacing: 0.125rem;
}

@mixin button1 {
	@include medium;

	font-size: 1.125rem;
	line-height: 1.4;
	letter-spacing: 0.01rem;
}

@mixin button2 {
	@include medium;

	font-size: 0.875rem;
	line-height: normal;
	letter-spacing: 0.1225rem;
	text-transform: uppercase;
}

@mixin tag1 {
	@include medium;

	font-size: 0.75rem;
	line-height: normal;
}

@mixin tag2 {
	@include bold;

	font-size: 0.75rem;
	line-height: 1.1;
	text-transform: uppercase;
}

@mixin tag3 {
	@include medium;

	font-size: 0.75rem;
	line-height: 1.4;
	letter-spacing: 0.09rem;
}

@mixin tag4 {
	@include bold;

	font-size: 0.625rem;
	line-height: 1.1;
	text-transform: uppercase;
}

/**
  * Bodies
  */

@mixin body1 {
	@include regular;

	font-size: 1.375rem;
	line-height: 1.3;

	letter-spacing: 0.01375rem;
}

@mixin body2 {
	@include regular;

	font-size: 1.25rem;
	line-height: 1.4;

	letter-spacing: 0.0125rem;
}

@mixin body3 {
	@include regular;

	font-size: 1.125rem;
	line-height: 1.4;

	letter-spacing: 0.01125rem;
}

@mixin body4 {
	@include regular;

	font-size: 1rem;
	line-height: 1.4;

	letter-spacing: 0.01rem;
}

@mixin body5 {
	@include regular;

	font-size: 0.875rem;
	line-height: 1.4;

	letter-spacing: 0.00875rem;
}

@mixin body6 {
	@include regular;

	font-size: 0.75rem;
	line-height: 1.4;

	letter-spacing: 0.0075rem;
}
