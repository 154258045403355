@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	background-color: #121212;
	color: $white;

	padding: 7rem 4rem;

	@include sm-down {
		padding: 4rem 1.25rem;
	}
}

.wrapper {
	display: grid;

	grid-template-columns: 1fr 1fr;

	gap: 5rem;

	align-items: center;

	max-width: 90rem;
	margin-inline: auto;

	@include sm-down {
		grid-template-columns: 1fr;
		gap: 2.5rem;
	}
}

.wrapper header {
	@include sm-down {
		order: 2;
	}
}

.image {
	width: 100%;
	height: 100%;
	aspect-ratio: 4 /3;
	max-height: 33rem;
	// max-height: clamp(20rem, 40vh, 40rem);

	img {
		object-fit: cover;
		height: 100%;
		width: 100%;
		border-radius: 20px;
	}

	@include sm-down {
		img {
			border-radius: 0.5rem;
		}
	}
}

.cta_container {
	margin-top: 2.5rem;
	@include sm-down {
		margin-top: 1.5rem;
	}
}

.container header {
	h2 {
		max-width: 16ch;
	}

	p {
		max-width: 40ch;
	}
}

.eyebrow {
	color: $brand-eight-blue;
}
