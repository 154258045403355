@import 'styles/constants';
@import 'styles/mixins/fonts';
@import 'styles/colors';
@import 'styles/colors-2024';
@import 'styles/layout-tokens';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/pod-3-fonts';
@import 'styles/mixins/fonts-2024';

.hero_container {
	position: relative;
}

.ef_hero_container {
	background: $background-white;
	position: relative;
}

.hero_header {
	color: $text-black;
	text-align: center;

	padding: 64px 1.25rem;

	h1 {
		margin-top: 0.5rem !important;
		text-wrap: balance;
	}

	a {
		margin-top: 2.5rem !important;
	}

	@include sm-down {
		padding: 4rem 1.25rem 2.5rem;
	}
}

.ef_hero_image_container {
	padding: 0 64px 64px;
	position: relative;

	> * {
		border-radius: 8px;
		overflow: hidden;
	}

	@include sm-down {
		padding: 0 1rem 1rem;
	}
}

.ef_hero_image {
	position: relative;
	z-index: 0;
}

.lock_badge {
	width: 48px;
	height: 32px;

	@include sm-down {
		width: 32px;
		height: 20px;
	}
}

.ef_logo {
	width: 160px;
	height: 64px;

	@include sm-down {
		width: 80px;
		height: 32px;
	}
}

.cycling_logos {
	width: 280px;

	@include sm-down {
		width: 140px;
	}
}

.logo {
	width: 210px;
	height: auto;
	z-index: 2;

	@include xs-only {
		width: 80px;
	}
}

.strava_badge {
	display: block;
	width: 300px;
	height: auto;
	z-index: 20;
	min-height: 152px;

	@include xs-only {
		width: 110px;
		min-height: 50px;
	}
}

.paceline_badge {
	display: block;
	width: 300px;
	height: auto;
	z-index: 20;

	@include xs-only {
		width: 110px;
	}
}

.paceline_cta {
	margin-top: 2rem;
}

.hero_image {
	position: relative;
	z-index: 0;
	margin-top: 42px;

	img {
		max-height: calc(100dvh - 40px);
	}

	.watermark {
		font-size: 12px;
		font-weight: 400;
		position: absolute;
		bottom: 15px;
		right: 15px;
		z-index: 2;
	}

	@include xs-only {
		aspect-ratio: 390 / 693;

		img {
			height: 100%;
		}

		.watermark {
			font-size: 9px;
			bottom: 5px;
			right: 5px;
		}
	}
}

.paceline_hero {
	position: relative;
	z-index: 0;

	img {
		max-height: calc(100dvh - 40px);
	}

	@include xs-only {
		aspect-ratio: 390 / 693;

		img {
			height: 100%;
			object-position: 85%;
		}
	}
}

.hero_content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 75px;
	width: 100%;
	height: 100%;
	z-index: 3;
	position: relative;

	> div {
		display: flex;
		column-gap: 50px;
		justify-content: center;
		align-items: center;

		@include xs-only {
			column-gap: 20px;
		}
	}

	@include sm-down {
		padding: 80px 10px;
		row-gap: 30px;
	}

	> h3 {
		@include headline3;
		text-align: center;
		margin: 0 auto;

		@include xs-only {
			@include headline8;
		}
	}
}

.two_quotes_container {
	@include section-padding;
	background: $background-black;
}

.ef_hero_content {
	position: absolute;
	padding: 2.5rem 3.5rem;
	left: 0;
	bottom: 0;
	z-index: 3;

	display: flex;

	align-items: center;
	gap: 65px;

	@include sm-down {
		gap: 12px;
		padding: 0.75rem 1rem;
	}
}

.shade {
	position: absolute;
	inset: 0;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.37) 100%);

	z-index: 2;
}
