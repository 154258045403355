@import 'styles/mixins/fonts-2023';
@import 'styles/mixins/media-queries';

.container {
	list-style: none;
	display: flex;

	border-radius: 0.5rem;
	background: rgba(255, 255, 255, 0.2);

	padding: 1rem 1.5rem;
	justify-content: center;
	align-items: center;
	gap: 2rem;

	backdrop-filter: blur(10px);

	margin: 0 auto 0.75rem;

	@include md-up {
		margin: 0 auto 0.5rem 0;
		gap: 2.5rem;
	}
}

.precision {
	display: flex;
	flex-direction: column;
	text-align: center;
	justify-content: center;
	align-items: center;

	position: relative;

	&:not(:last-child) {
		&:after {
			content: '';
			position: absolute;
			right: -1rem;
			width: 1px;
			height: 60%;
			background: rgba(255, 255, 255, 0.4);

			@include md-up {
				right: -1.25rem;
			}
		}
	}
}

.number {
	@include subheading3;
	line-height: 1.1;

	@include md-up {
		@include subheading2;
	}
}

.label {
	@include body7;
	opacity: 0.8;

	@include md-up {
		@include body6;
	}
}
