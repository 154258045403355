@import 'fonts-2024';

@mixin blue_tag {
	display: inline-flex;
	@include eyebrow1;

	color: #5289fe;

	border-radius: 0.25rem;
	background: rgba(82, 137, 254, 0.3);

	padding: 0.25rem 0.5rem;
}
