@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';

.container {
	background-color: $white;
	color: $black;

	max-height: 100rem;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	padding: $spacing-xx-large-desktop $spacing-xx-large-desktop 0;

	@include sm-down {
		padding: $spacing-xxx-large-mobile $spacing-medium-mobile 0;
	}

	@include xs-only {
		min-height: unset;
	}
}

.container header {
	text-align: center;

	h1 {
		max-width: 16ch;
		margin-inline: auto;
		margin-top: 1rem;
	}
}

.ctas {
	display: flex;
	gap: 1rem;
	justify-content: center;
	align-items: center;

	margin-top: 2.5rem;

	@include xs-only {
		flex-direction: column;
		button {
			width: 100%;
		}
	}
}

.video_container {
	flex: 1;
	position: relative;
	border-radius: 0.5rem;

	margin-top: 5rem;

	contain: paint;

	cursor: pointer;

	&:hover {
		svg {
			opacity: 1;
		}
	}

	svg {
		opacity: 0;
		position: absolute;
		z-index: 1;
		inset: 0;
		margin: auto;

		$dim: clamp(3rem, 12vw, 6rem);
		height: $dim;
		width: $dim;

		transition: opacity 0.25s ease-out;
	}

	video {
		aspect-ratio: 16/9;
		object-fit: cover;
		border-radius: 0.5rem;

		max-width: 90rem;
	}

	@include xs-only {
		margin-top: 4rem;
		svg {
			opacity: 1;
		}
	}
}

.modal_video_container {
	display: flex;
	justify-content: center;
	align-items: center;

	position: relative;
	width: 100%;
	height: 100%;

	video {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
}
