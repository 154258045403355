@import 'styles/constants';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/theme';

.button {
	@include buttonlink;

	&:global(:disabled) {
		border-color: $brand-primary-light;
		background-color: $brand-primary-light;
	}
}

.button_reset {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
}
