@import 'styles/constants';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/theme';

.c_video {
	margin: 0;
	padding: 0;
	position: relative;

	video {
		display: block;
	}

	button.a_button {
		position: absolute;
		right: 10px;
		bottom: 10px;
		z-index: 99;
		width: 35px;
		height: 35px;
		padding: 0;
		background-size: cover;
		background-color: #fff;
		border-radius: 50%;
		background-position: center center;
		cursor: pointer;
		border: none;
		opacity: 0;

		&:global(.play) {
			background-image: url('https://eightsleep.imgix.net/icons_play.svg?v=1650776727');
		}

		&:global(.pause) {
			background-image: url('https://eightsleep.imgix.net/icons_pause.svg?v=1650776727');
		}

		&:focus-visible {
			outline: 4px solid $brand-primary;
			opacity: 1;
		}
	}

	&:hover button.a_button {
		opacity: 1;
	}
}

.controls {
	background-color: transparent;
	border: none;

	// border: 2px solid #fff;
	height: 26px;
	width: 26px;

	border-radius: 50%;
	color: transparent;
	overflow: hidden;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 0;
	right: 0;
	margin: auto;

	&:global(.play) {
		background-image: var(--play-icon);
	}

	&:global(.pause) {
		opacity: 0;
		inset: 0;
		width: 100% !important;
		height: 90% !important;
		transform: none;
	}
}

.video_background {
	position: relative;
}
