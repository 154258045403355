@import 'styles/constants';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	max-width: 1250px;
	margin: 0 auto;
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 140px;

	@include sm-down {
		grid-template-columns: 1fr;
		gap: 24px;

		.mobile_right {
			text-align: right;

			img {
				margin-left: auto;
			}
		}
	}

	.wrapper {
		display: flex;
		flex-direction: column;
	}

	.ccc {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.img_container img {
		border-radius: 25px;

		@include sm-down {
			max-width: 85%;
		}
	}

	&:not(:last-child) {
		margin-bottom: 80px;
	}

	&:nth-child(odd) {
		@include md-up {
			.img_container {
				order: 2;
			}
		}
	}
}

.heading {
	text-align: center;
	max-width: 25ch;
	margin: 0 auto;
}

.quotes_description {
	max-width: 53ch;
	margin: 40px auto 100px;
	text-align: center;

	@include xs-only {
		margin: 20px auto 70px;
	}
}
