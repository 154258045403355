@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';
@import 'styles/mixins/fonts-decorated';

.container {
	background-color: $white;
	color: $white;

	position: relative;

	min-height: 100vh;
	margin: 0;

	@include xs-only {
		margin: 0;
	}

	padding-top: 100vh;

	isolation: isolate;
}

.tag {
	@include blue-tag;
	margin-bottom: 0.5rem;
	opacity: 0;
	text-align: center;

	@include sm-down {
		margin-bottom: 1.5rem;
	}
}

.heading {
	position: sticky;
	top: 0;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;

	isolation: isolate;

	padding: 1rem;
}

.video_rails {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: -1;
}

.heading_rails {
	position: absolute;
	top: 0;
	left: 0;
	height: 150vh;
	width: 100%;
	z-index: 0;

	mix-blend-mode: difference;
}

.video_bg {
	position: sticky;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100%;
	object-fit: cover;
}

.card_section {
	max-width: 70rem;
	display: grid;
	grid-template-columns: 1fr 1fr;

	margin-inline: auto;

	gap: 4rem;

	padding: 16rem 2rem 0;

	@include sm-down {
		grid-template-columns: 1fr;
		align-items: center;
		margin: 0 2rem;
		padding: 10rem 2rem 0;
	}

	@include xs-only {
		padding: 16rem 2rem 0;
	}
}

.card_companion_text {
	display: flex;
	flex-direction: column;

	@include sm-down {
		align-items: center;
	}
}

.card_companion_text p {
	max-width: 35ch;

	@include sm-down {
		text-align: center;
	}
}

.alarm_section {
	display: flex;
	flex-direction: column;
	align-items: center;

	padding: min(20rem, 25vh) 0;

	gap: 8rem;

	h3 {
		@include headline2;

		max-width: 10ch;
		margin-left: 20vw;
	}

	p {
		@include headline5;

		max-width: 27ch;

		mix-blend-mode: difference;

		margin-right: 20vw;
	}

	@include sm-down {
		h3 {
			@include headline5;
		}

		p {
			@include body1;
			margin: 2rem;
		}
	}
}

.card_list {
	list-style: none;
	display: flex;
	margin-bottom: 10rem;
	//margin-top: 4rem;
	align-items: center;
	gap: 1rem;

	li {
		overflow: hidden;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
	}

	@include sm-down {
		flex-direction: column-reverse;
	}
}

.card {
	display: inline-flex;

	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 0.5rem;

	text-align: center;

	position: relative;
	width: 100%;
	border-radius: 0.5rem;

	box-shadow: hsla(0, 0%, 100%, 0.3) 1.25px 0 1px 0 inset, hsla(0, 0%, 100%, 0.3) -1.5px 0 1px 0 inset, hsla(0, 0%, 100%, 0.1) 0 -1.5px 1px inset, hsl(0, 0%, 100%) 0 1.5px 0.5px inset;

	background: rgba(255, 255, 255, 0.08);
	backdrop-filter: blur(5px);

	padding: 0.75rem 3rem;
	@include sm-down {
		padding: 2rem 1.25rem;
	}
}
