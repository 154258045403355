@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	background-color: $white;
	color: $black;
	padding: $spacing-xl $spacing-md;

	//margin: 0 1rem;
	@include xs-only {
		padding: $spacing-xl $spacing-sm;
	}
}

.content {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	justify-content: center;
	max-width: 1123px;
	align-items: center;
	margin: auto;
}

.title {
	@include headline3;
	font-size: 6.25rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.1;
	text-align: center;
	@include xs-only {
		font-size: 3.5rem;
	}
}

.description {
	@include headline7;
	font-weight: 400;
	color: #717171;
	line-height: 1.3; /* 31.2px */
	letter-spacing: 0.015rem;
	max-width: 607px;
}

.ctas {
	display: flex;
}

.ctaButton {
	span {
		@include body4;
	}
}

.learnMore {
	span {
		@include button2;
	}
	color: #717171;
}

.stepContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1.7rem;
	text-align: center;
}

.headlineStep {
	@include headline6;
}

.descriptionStep {
	@include body1;
	color: #717171;
	max-width: 335px;
}
