@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2023';
@import 'styles/colors-2023';
@import 'styles/layout-tokens';

.container {
	background-color: $neutral-white;
	outline: $neutral-white solid 1px;
	color: $neutral-black;
}

.container_fullscreen {
	padding: 2rem;

	@include md-up {
		padding: 7.5rem 1.5rem 3.75rem;
		height: unset;
		width: unset;
	}
}

.inner {
	overflow: hidden;
	height: 100%;

	display: grid;
	grid-template-rows: auto minmax(0, 1fr);
	gap: 2rem;

	@include md-up {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr;
		gap: 2rem;
		max-width: 1200px;
		margin-inline: auto;
	}
}

.content {
	display: grid;
	grid-template-columns: 1fr auto;

	@include md-up {
		height: 100%;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr 1fr;
		row-gap: 2rem;
		column-gap: 2rem;
		grid-column: 2;
	}
}

.img_container {
	max-height: 360px;
	display: none;
	@include md-up {
		display: block;
	}
	height: 100%;
	position: relative;

	img {
		position: absolute;
		width: 100%;
		height: 100%;
	}
}

.object_position_top {
	img {
		object-position: top;
	}
}

.text {
	h2 {
		@include heading5;
		margin-bottom: 1rem;
		max-width: 10ch;
	}

	p {
		@include body4;
		max-width: 30ch;
	}

	@include sm-up {
		h2 {
			max-width: unset;
		}

		p {
			max-width: unset;
		}

		max-width: 50ch;
	}

	@include md-up {
		grid-column: 1 / 3;

		h2 {
			@include heading4;
			margin-bottom: 0.625rem;
			max-width: 17ch;
		}

		p {
			@include body3;
			max-width: 45ch;
			opacity: 0.6;
		}

		max-width: unset;
	}
}

.desktop_photos {
	display: none;
	@include md-up {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 2rem;
	}
}

.video {
	position: relative;
	@include md-up {
		aspect-ratio: unset;
		grid-column: 1;
		grid-row: 1;
	}

	video {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
}

.cta {
	margin-top: 1rem;
}
