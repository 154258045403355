@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';

.container {
	background-color: $black;
	color: $white;
}

.inner_container {
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	grid-template-rows: repeat(3, auto);

	padding: 4rem 0;

	row-gap: 1.25rem;

	column-gap: min(8rem, 6vw);

	max-width: 80rem;
	margin-inline: auto;

	@include sm-down {
		grid-template-rows: repeat(3, auto);
		grid-template-columns: 1fr;

		gap: 4rem;
	}
}

$text-bump: -6rem;

.container h2 {
	text-align: center;

	max-width: 20ch;
	margin-inline: auto;

	padding: 0 2rem;

	grid-row: 1;

	@include md-up {
		text-align: left;

		grid-column: 2;

		margin-inline: unset;

		align-self: end;

		transform: translateY($text-bump);
	}
}

.container p {
	opacity: 0.75;
	max-width: 38ch;
}

.cta_button {
	padding: 0 2rem;

	grid-row: 2;
	grid-column: 2;

	transform: translateY($text-bump);
	@include sm-down {
		text-align: center;

		grid-row: 3;
		grid-column: 1;

		transform: none;

		margin-inline: auto;
	}
}

.video_container {
	position: relative;

	min-height: 20rem;

	width: 80rem;
	height: 50rem;
	justify-self: end;

	video {
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
	}

	grid-row: 1 / 3;

	@include sm-down {
		width: 100%;
		height: 100%;
		grid-row: 2;

		video {
			right: 0;
			left: 0;
			width: 100%;
		}
	}

	@include xs-only {
		video {
			width: 40rem;
			left: auto !important;
		}
	}
}
