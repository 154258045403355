@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	background-color: $background-white;
	color: $text-black;

	@include section-padding;
}

.wrapper {
	max-width: $max-width;
	margin-inline: auto;
}

.container h2 {
	text-align: center;

	margin-bottom: 12rem;

	max-width: 18.5ch;
	margin-inline: auto;

	@include sm-down {
		margin-bottom: 6rem;
	}
}

.container article {
	&:not(:last-child) {
		margin-bottom: 6rem;
	}
}

.container article header {
	text-align: center;
	margin-bottom: 4rem;

	@include sm-down {
		margin-bottom: 2.5rem;
	}
}

.container article header h3 {
	max-width: 19ch;
	margin-inline: auto;
}

.container article header p {
	max-width: 60ch;
	margin-inline: auto;
	color: $text-grey-dark;
}

.snoreImg {
	img {
		border-radius: 1.5rem;
		@include xs-only {
			border-radius: 0.5rem;
			box-shadow: 0px 16px 34px 0px rgba(0, 0, 0, 0.1);
		}
	}
}

.image_container {
	margin-top: 8rem;
	@include sm-down {
		margin-top: 4rem;
	}
	@include xs-only {
		margin-top: 0;

		margin-inline: auto;
	}
	position: relative;

	.snoreCard {
		position: absolute;
		top: 10%;
		right: calc(86%);
		width: clamp(220px, 22vw, 440px);
		transform: translate(50%, -50%);

		&:hover {
			transform: translate(50%, -50%) scale(1.05);
			filter: drop-shadow(0 0 12px rgba(0, 0, 0, 0.25));
		}

		transition: transform 0.25s ease-out, filter 0.25s ease-out;

		z-index: 1;
		@include xs-only {
			top: 90%;
			left: -20%;
			right: auto;
			width: 220px;
		}
	}
}

.asterisk {
	@include regular;
	font-size: 2rem;
	align-items: flex-start;
	vertical-align: super;
	@include xs-only {
		color: #000;
		vertical-align: baseline;
	}
}
