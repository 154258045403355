@import 'styles/mixins/fonts';
@import 'styles/colors';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2023';
@import 'styles/colors-2023';

.tonal_modal {
	align-items: flex-end;

	margin: 0 0 10px 0;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;

	.ccc {
		text-decoration: underline;
		display: inline-block;
		margin-left: 5px;

		button {
			font-size: 12px;
			color: #797c8e;
			margin-top: 0;
			margin-bottom: 0;
			line-height: 1;

			> picture {
				top: -2px;
				left: 1px;
			}
		}
	}
}

.trigger {
	background: transparent;
	border-radius: 3px;
	outline: none;
	border: none;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 20px 0;
	width: 100%;
	margin: 1rem 0 0;

	cursor: pointer;

	span {
		margin-left: 0.5rem;
		width: 1rem;
		height: 1rem;
	}

	p {
		font-size: 12px;
		line-height: 140%;
		display: flex;
		align-items: center;
		margin-bottom: 0;
		color: #636363;
	}
}

.modal_trigger_new {
	margin: 1rem 0 0;
	width: 100%;
	padding: 1rem;
	border-radius: 5px;
	background-color: #f7f8fa;
	text-align: left;
	position: relative;
	justify-content: flex-start;

	p {
		@include body6;
	}

	svg {
		position: absolute;
		top: calc(50% - 0.75rem);
		right: 0.75rem;
		width: 1.25rem;
		height: 1.25rem;
	}

	&:hover {
		background-color: $neutral-6;
	}
}

.tooltip_icon {
	position: relative;
	left: 5px;
	vertical-align: middle;
	display: inline-flex;

	filter: saturate(0);
	opacity: 0.7;

	img {
		width: 14px;
		height: 14px;
	}
}

.content {
	background-color: #fff;
	max-width: calc(100vw - 40px);

	width: 980px;
	padding: 60px;
	position: relative;

	z-index: 1000;

	@include xs-only {
		padding: 30px;
		padding-top: 60px;
	}

	display: flex;
	flex-direction: column;

	border-radius: 5px;

	transition: all 0.4s ease;
}

.modal_h.modal_h {
	@include sm-down {
		@include h4-mobile;
		text-align: center;
		margin-bottom: 2rem;
	}

	@include xs-only {
		margin-bottom: 30px;
		padding: 0;
		font-size: 20px;
		font-weight: 700;
		line-height: 1.3;
		text-align: center;
	}

	@include h3-desktop;
	font-size: 48px;
	font-weight: 700;
	line-height: 1.3;
	margin-bottom: 30px;
	text-align: center;
}

.modal_content_container {
	background-color: #fff;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	overflow-y: auto;
	margin: 0 20px;
	line-height: 1.4;
	box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.15);
	border-radius: 10px;

	@include xs-only {
		max-height: 90vh;
		justify-content: flex-start;
	}
}

.close_button {
	position: absolute;
	top: 30px;
	right: 30px;
	z-index: 10000;
	cursor: pointer;
	border: none;
	background-color: rgb(255 255 255 / 75%);
	border-radius: 100px;
	width: 24px;
	height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		width: 28px;
	}

	@include xs-only {
		top: 15px;
		right: 15px;
	}
}

.table_container {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	column-gap: 15px;
	justify-content: center;
	row-gap: 15px;

	@include sm-down {
		grid-template-columns: 1fr;
		justify-content: center;
	}

	> div {
		background-color: $ui-mid-grey;
		padding: 30px;
		display: flex;
		flex-direction: column;
		max-width: 290px;
		font-size: 16px;
		row-gap: 15px;

		.subline {
			height: 15px;
			font-size: 11px;
			color: #d9682a;

			@include sm-down {
				display: none;
			}
		}

		> span:last-of-type {
			margin-top: auto;
		}

		@include sm-down {
			max-width: none;
		}

		@include xs-only {
			padding: 20px;
		}
	}
}
