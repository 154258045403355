$brand-primary: #fff;
$brand-lighten: #fff9f2;
$brand-secondary: #1862ff;

$neutral-black: #000;

$neutral-1: #171920;

$neutral-2: #636363;

$neutral-3: #a0a0a0;

$neutral-4: #d0d0d0;

$neutral-5: #edebe9;

$neutral-6: #eee;

$neutral-7: #f2f0eb;

$neutral-8: #f2f4f6;

$neutral-white: #fff;

$accent-1: #d33545;

$accent-2: #ab01ff;

$accent-3: #4c39f2;

$accent-4: #1862ff;

$accent-5: #002173;

$solid-blue: #0249a0;

/**
@deprecated
 */
$temperature-warm: #e86b6b;
$temperature-cool: #4c39f2;
