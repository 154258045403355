@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	background-color: $white;
	color: $black;
}

.container header {
	text-align: center;
	padding: 7rem 4rem 0;

	margin-bottom: 2.5rem;

	@include sm-down {
		padding: 4rem 1.25rem 0;
	}
}

.container header small {
	@include eyebrow1;

	display: block;

	color: rgba(0, 0, 0, 0.7);

	margin-bottom: 1rem;

	@include sm-down {
		margin-bottom: 1.5rem;
	}
}

.container header h2 {
	@include headline3;
	font-size: 3.25rem; // TODO out of spec

	max-width: 22ch;
	margin-bottom: 2rem;
	margin-inline: auto;

	@include sm-down {
		font-size: 2.75rem;
		margin-bottom: 1.5rem;
	}
}

.container header p {
	@include body2;

	color: rgba(0, 0, 0, 0.7);

	text-align: center;

	max-width: 48ch;
	margin-inline: auto;

	@include sm-down {
		@include body2;
		margin-bottom: 2rem;
		margin-inline: auto;
		max-width: unset;
	}
}

.image {
	img {
		max-width: 90rem;
		margin-inline: auto;
	}

	@include sm-down {
		img {
			height: 25rem;
		}
	}
}

.table {
	max-width: 90rem;
	margin-inline: auto;

	border-spacing: 0;

	padding: 4rem 4rem 7rem;

	height: 100%;

	@include sm-down {
		padding: 2rem 1.25rem 4rem;
	}
}

.eight_logo {
	height: 2rem;
	width: auto;

	@include sm-down {
		height: 1.5rem;
	}
}

.checkmark,
.xmark {
	width: 1.25rem;
	height: 1.25rem;

	@include sm-down {
		width: 1rem;
		height: 1rem;
	}
}

$desktop-cell-padding: 0 1.25rem;
$mobile-cell-padding: 0 0.75rem;

.table th:nth-child(2) {
	padding: $desktop-cell-padding;

	@include sm-down {
		padding: $mobile-cell-padding;
	}
}

.table th:nth-child(3) {
	padding: 0;
}

.eight_logo_container {
	display: block;
	border-radius: 1rem 1rem 0 0;
	background: rgba(229, 237, 255, 0.7);
	padding: 2rem;

	@include sm-down {
		padding: 1rem;
	}
}

.table th:not(:first-child) {
	margin-left: 2rem;
}

.others_container {
	border-radius: 1rem 1rem 0 0;

	background: rgba(0, 0, 0, 0.1);
	padding: 2rem;

	height: 100%;

	@include headline6;
	font-size: 1.75rem; // TODO out of spec

	@include sm-down {
		padding: 1rem;
		font-size: 1.125rem; // TODO out of spec
	}
}

.table td:nth-child(1) {
	padding: 0;
}

.table td:nth-child(2) {
	text-align: center;

	div {
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(229, 237, 255, 0.7);

		padding-top: 0.75rem;
		padding-bottom: 0.75rem;

		@include sm-down {
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;
		}

		height: 100%;
	}

	padding: $desktop-cell-padding;

	@include sm-down {
		padding: $mobile-cell-padding;
	}
}

.table td:nth-child(3) {
	text-align: center;

	div {
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(0, 0, 0, 0.1);

		padding-top: 0.75rem;
		padding-bottom: 0.75rem;

		height: 100%;

		@include sm-down {
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;
		}
	}
}

.table_row td {
	padding: 0;
}

.table_row_name {
	padding: 0.25rem 0;
}

.table_row:last-child td:nth-child(2) {
	position: relative;
	&::after {
		content: '';
		position: absolute;
		left: 1.25rem;
		right: 1.25rem;
		height: 1.75rem;
		background: rgba(229, 237, 255, 0.7);

		border-radius: 0 0 1rem 1rem;

		@include sm-down {
			height: 1rem;
			left: 0.75rem;
			right: 0.75rem;
		}
	}
}

.table_row:last-child td:nth-child(3) {
	position: relative;
	&::after {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		height: 1.75rem;
		background: rgba(0, 0, 0, 0.1);

		border-radius: 0 0 1rem 1rem;

		@include sm-down {
			height: 1rem;
		}
	}
}

.table_row p {
	@include body2;
	@include sm-down {
		@include body4;
	}
}
