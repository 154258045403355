@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2023';
@import 'styles/colors-2023';

.container {
	color: $neutral-black;
	text-align: center;
	max-width: 70rem;
	margin-inline: auto;

	h2 {
		@include body5;
		color: #d9682a;
		margin: 0 auto 1rem;
	}

	h3 {
		@include heading5;
		max-width: 20ch;
		margin: 0 auto 2rem;
	}

	@include md-up {
		h2 {
			@include body4;
			margin: 0 auto 1rem;
		}

		h3 {
			@include heading4;
			margin: 0 auto 2rem;
		}
	}
}

.features_list {
	list-style: none;
	margin: 0;
	padding: 0;

	> li {
		&:not(:last-child) {
			padding-bottom: 4rem;
		}
	}

	@include md-up {
		> li {
			padding: 4rem;
		}
	}
}

.split_container {
	display: block;
	position: relative;

	@include md-up {
		display: grid;
		justify-content: center;
		align-items: center;
		grid-template-columns: 1fr 1fr;

		gap: 4rem;

		&:nth-child(odd) {
			.text_content {
				order: 1;
			}

			.side_content {
				order: 2;
			}

			.notif_wrapper {
				justify-content: flex-end;
			}

			.notif_container {
				right: -1rem;
				left: auto;
			}
		}
	}
}

.text_content {
	padding: 1.5rem;

	@include md-up {
		padding: 0;
		margin-top: 0;
	}
}

.text_content_inner {
	display: inline-flex;
	flex-direction: column;
	gap: 2rem;
	text-align: left;

	h4 {
		@include subheading2;
		max-width: 22ch;
	}

	p {
		@include body5;
		max-width: 42ch;
	}

	@include md-up {
		h4 {
			@include subheading1;
		}
	}
}

.icon_container {
	padding: 0.5rem;
	border-radius: 10px;
	box-shadow: $neutral-4 0 0 0 1px;
	width: 2.5rem;
	height: 2.5rem;

	display: none;

	@include md-up {
		display: block;
	}
}

.side_content {
	position: relative;

	height: auto;

	@include md-up {
		height: 35rem;
	}
}

.iphone {
	display: block;
	height: 100%;

	img {
		display: block;
		height: 100%;
	}

	@include sm-down {
		height: 30rem;

		img {
			height: 100%;
		}
	}
}

.vibrate_gif {
	max-height: 35rem;

	object-fit: cover;

	@include sm-down {
		height: 30rem;
		width: 100%;
	}
}

.icons_grid {
	display: grid;
	list-style: none;
	justify-items: center;
	align-items: center;
	gap: 2rem;

	margin: 0;
	padding: 0;
}

.icon_grid_item {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 0.5rem;
	text-align: center;
	max-width: 10rem;

	svg {
		width: 1.5rem;
		height: 1.5rem;
	}

	p {
		@include body7;
	}
}

.hotter_notification {
	min-height: 3rem;
	width: min(17rem, 90vw);

	@include md-up {
		min-height: 4rem;
		width: min(20rem, 90vw);
	}
}

.reports_notification {
	width: 14rem;
	height: 14rem;
}

.alarm_notification {
	display: block;
	margin: 1rem;

	width: 9.5rem;
	height: 10.5rem;
}

.side_notification {
	display: block;
	width: 9rem;
	height: 7rem;

	margin: 1rem 0.5rem;
}

.side_notification_custom {
	display: block;
	width: 100%;
	margin: 0;
}

.notif_wrapper {
	display: flex;
	width: 100%;
}

.notif_container {
	border-radius: 0.75rem;
	background: rgba(49, 49, 49, 0.7);
	backdrop-filter: blur(10px);
	padding: 0.5rem;

	position: absolute;
	left: 50%;
	transform: translate(-50%, 50%);
	bottom: 0;

	@include md-up {
		padding: 0.75rem;

		bottom: -1rem;
		left: -1rem;
		right: unset;

		transform: none;
	}
}

.mobile_spacer {
	@include md-up {
		display: none;
	}
}
