@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	background-color: $background-off-white;
	color: $black;

	@include section-padding;
}

.wrapper {
	@include max-width-center;

	display: grid;
	grid-template-columns: 1fr auto;
	gap: 32px;

	@include sm-down {
		grid-template-columns: 1fr;
	}
}

.content {
	padding-block: 20px;
}

.content header {
	margin-block-end: 24px;

	text-wrap: balance;
}

.benefits {
	list-style: none;
	margin: 0;
	padding: 0;
	margin-block-end: 40px;

	display: flex;
	flex-wrap: wrap;
	gap: 12px;

	li {
		display: flex;
		align-items: center;
		gap: 8px;
	}
}

.description {
	max-width: 40ch;
	color: $text-grey-dark;
}

.image_container {
	contain: paint;

	@include sm-down {
		order: 2;

		max-height: 15rem;
	}
}

.image {
	height: 100%;

	img {
		width: 100%;
		height: 100%;
		border-radius: $br-sm;
	}
}
