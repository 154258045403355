/*

	Typography Update Spring 2022
	Based on Web Design Library 2022 Figma
	https://www.figma.com/file/N2wo4yXJqDqjtfNIVXha2h/Web-Design-Library-2022?node-id=193%3A2502

 */

@mixin aktiv-grotesk-default {
	font-family: var(--neue-montreal), sans-serif;
	font-style: normal;
	line-height: 140%;
}

@mixin header {
	@include aktiv-grotesk-default;
	font-weight: 700;
}

@mixin paragraph {
	@include aktiv-grotesk-default;
	font-weight: 400;
}

/* Desktop Headers */

@mixin h1-desktop {
	@include header;
	font-size: 48px;
}

@mixin h2-desktop {
	@include header;
	font-size: 40px;
}

@mixin h3-desktop {
	@include header;
	font-size: 32px;
}

@mixin h4-desktop {
	@include header;
	font-size: 24px;
}

@mixin h5-desktop {
	@include header;
	font-size: 18px;
}

@mixin h6-desktop {
	@include header;
	font-size: 16px;
}

/* Mobile Headers */

@mixin h1-mobile {
	@include header;
	font-size: 36px;
}

@mixin h2-mobile {
	@include header;
	font-size: 32px;
}

@mixin h3-mobile {
	@include header;
	font-size: 28px;
}

@mixin h4-mobile {
	@include header;
	font-size: 24px;
}

@mixin h5-mobile {
	@include header;
	font-size: 20px;
}

@mixin h6-mobile {
	@include header;
	font-size: 18px;
}

@mixin h7-mobile {
	@include header;
	font-size: 14px;
}

/* Desktop Paragraphs */

@mixin p-lrg-desktop {
	@include paragraph;
	font-size: 18px;
}

@mixin p-reg-desktop {
	@include paragraph;
	font-size: 16px;
}

@mixin p-sml-desktop {
	@include paragraph;
	font-size: 14px;
}

@mixin p-xs-desktop {
	@include paragraph;
	font-size: 12px;
}

/* Mobile Paragraphs */

@mixin p-lrg-mobile {
	@include paragraph;
	font-size: 16px;
}

@mixin p-reg-mobile {
	@include paragraph;
	font-size: 14px;
}

@mixin p-sml-mobile {
	@include paragraph;
	font-size: 12px;
}
