@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';

.container {
	background-color: $white;
	color: $black;

	@include section-padding;
	display: flex;
	flex-direction: column;

	align-items: center;
}

.container h2 {
	text-align: center;

	max-width: 15ch;

	margin-inline: auto;
}

.table {
	margin: 5rem auto;

	border-spacing: 0;

	@include sm-down {
		margin: 3rem auto;
	}
}

.table th {
	display: flex;
	align-items: center;
}

.table td {
	display: flex;
	align-items: center;
}

.table tr {
	display: grid;
	grid-template-columns: minmax(0, 18rem) repeat(4, minmax(0, 12.5rem));
	border-bottom: 1px solid #d7d5d2;
}

.table th,
.table td:not(:first-child) {
	padding: 1rem 1.5rem;

	justify-content: center;

	@include xs-only {
		padding: 0.5rem 0;
	}
}

.table th:nth-child(2) {
	border-radius: 1rem 1rem 0rem 0rem;
	color: #1862ff;
}

.table th:nth-child(2),
.table td:nth-child(2) {
	background: #e5edff;
}

.table td:nth-child(1) {
	padding: 1rem 0.5rem 1rem 0;

	@include xs-only {
		padding: 0.5rem 0.25rem 0.5rem 0;
	}
}

.table svg {
	width: 2rem;
	height: 2rem;

	margin-inline: auto;

	@include xs-only {
		width: 1rem;
		height: 1rem;
	}
}

.none {
	display: block;
	width: 1rem;
	height: 0.125rem;
	background-color: #d3d3d3;
	border-radius: 0.125rem;

	@include xs-only {
		width: 0.5rem;
		height: 1px;
	}
}
